.slider-thumbs {
	padding-block: var(--sp-10x);
	position: relative;

	&__heading {
		margin-block-end: var(--sp-4x);
	}

	&__list {
		@extend %list-overflow-scroll;
	}

	&__item {
		margin-inline-end: var(--sp-4x);
		flex-shrink: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	&__figure {
		margin-block-end: var(--sp-1x);
	}

	&__image {
		aspect-ratio: 16 / 9; //sass-lint:disable-line no-misspelled-properties
		object-fit: cover;
		object-position: top;
	}

	.swiper {
		@include z-index('bungalow')
	}
}