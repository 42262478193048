.slider-hero {
	@include size(100%, var(--app-height));
	position: relative;
	@extend %clip-path-jumbotron-hero;
	@extend %fix-safari-overflow-scroll;


	&::before,
	&::after {
		background-color: var(--red);
		@include z-index('bungalow')
	}

	&__picture {
		@include absolute(top 0 left 0);
		@include z-index('pothole');
		@include size(100%);
		@extend %overlay;
	}

	&__image {
		object-fit: cover;
		object-position: top;
		aspect-ratio: 1 / 1; //sass-lint:disable-line no-misspelled-properties

		@include bp('large') {
			aspect-ratio: 16 / 9; //sass-lint:disable-line no-misspelled-properties
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		@include size(100%);
		@extend %grid-small;
		padding-block-end: var(--sp-5x);

		@include bp('medium') {
			width: 85vw;
		}
	}

	&__heading {
		margin-block-end: var(--sp-3x);

		&--pb {
			margin-block-end: var(--sp-5x);

			@include bp('medium') {
				margin-block-end: var(--sp-8x);
			}

			@include bp('extra-large') {
				margin-block-end: var(--sp-10x);
			}
		}
	}

	&__link {
		margin-block-end: var(--sp-5x);
		align-self: flex-start
	}

	&__animation {
		@include absolute(left 11% bottom 5rem);
		@include z-index('house');
		height: 4rem;

		@include bp('extra-large') {
			height: 11rem;
		}

		&::after {
			content: '';
			background: url('/assets/img/layout/arrow.svg') no-repeat 0 0;
			@include size(1.8rem, 1rem);
			@include absolute(bottom -1rem left -.8rem);
		}

		&-line {
			display: block;
			@include size(.2rem, 100%);
			position: relative;
			overflow: hidden;
			background-color: var(--white-50);

			&::after {
				content: '';
				background-color: var(--white);
				@include size(100%);
				@include translateY(-100%);
				@include absolute(top 0 left 0);
				animation: scrollDown 2s infinite ease-in-out
			}

		}
	}

	&__btn {
		margin-bottom: 2rem;
		align-self: flex-start;
		@extend %flex-center-v;

		&-icon {
			@include size(6rem);
			border-radius: 50%;
			background-color: var(--red);
			@extend %flex-center;
			margin-right: 2rem;
		}

		&-svg {
			width: 50%;
			margin-left: 10%;
			path {
				fill: var(--white);
			}
		}
	}

	@include keyframes(scrollDown) {
		0% {
			@include translateY(-100%)
		}

		100% {
			@include translateY(100%)
		}
	}

	.swiper {
		z-index: auto;
		--swiper-theme-color: var(--white);
		--swiper-pagination-bullet-opacity: 1;
		--swiper-pagination-bullet-inactive-opacity: 1;
		--swiper-pagination-color: var(--white);
		--swiper-pagination-bullet-inactive-color: transparent;
		--swiper-pagination-bullet-width: 1.2rem;
		--swiper-pagination-bullet-height: 1.2rem;

		&-pagination {
			@include z-index('house');
			width: 1.2rem;
			left: auto;
			bottom: 2rem;
			right: 6%;

			@include bp('extra-large') {
				right: 8%;
			}

			&-bullet {
				border: .1rem solid var(--swiper-theme-color);
			}

			//override swiper css without important
			&-bullets {
				.swiper-pagination-bullet {
					margin-inline: 0;
					margin-block-end: var(--sp-1x);
				}
			}
		}
	}
}