.social-media {
	@extend %grid;
	padding-left: 0;
	padding-block-start: var(--sp-13x);
	@extend %fix-safari-overflow-scroll;

	@include bp('large') {
		display: flex;
		align-items: flex-end;
		flex-direction: row-reverse;
	}

	&__column {
		position: relative;

		@include bp('large') {
			flex-basis: 50%;
		}

		&:first-child {
			padding-inline-start: 6%;
			margin-block-end: var(--sp-10x);

			@include bp('large') {
				padding-inline-start: 8%;
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-inline-end: -20%;

			@include bp('large') {
				margin-right: 0;
			}
		}
	}

	&__copy {
		display: block;
		margin-block-end: var(--sp-4x)
	}

	&__list {
		@extend %flex-between;
		margin-block-end: var(--sp-9x);
	}

	&__item {
		flex-basis: 50%;
	}

	&__link {
		padding-block: var(--sp-1x);
	}

	&__strong {
		display: block;
	}

	&__figure {
		position: relative;
		@include z-index('bungalow')
	}

	&__image {
		display: block;
		@include rotate(8);
		transform-origin: 0 0;
		object-fit: cover;
		aspect-ratio: 15 / 13; //sass-lint:disable-line no-misspelled-properties
	}

	&__square {
		background-color: var(--red);
		aspect-ratio: 15 / 13; //sass-lint:disable-line no-misspelled-properties
		@include rotate(8);
		transform-origin: 0 0;
	}

	&__container {
		@extend %position-center-v;
		@include z-index('bungalow');
		padding-inline: 6%;
		width: 88%;

		@include bp('large') {
			width: auto;
			padding: 0;
			left: 50%;
			@include translate(-50%, -50%)
		}
	}

	&__title {
		margin-block-end: var(--sp-6x);
	}
}