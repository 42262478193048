.nav {
	@include z-index('sky');
	@include fixed(top 0 left 0);
	@include size(100%, calc(100% - 10rem));
	@include translate(0, -100%);
	background-color: var(--blue);
	transition: .5s transform ease(out-sine);
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-block-end: var(--sp-5x);

	&--open {
		@include translate(0, 10rem)
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		@include size(100%, auto);
		overflow-y: auto;
		padding-block: var(--sp-2x);
	}

	&__item {
		margin-block-end: 7vh;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		position: relative;
		display: inline-flex;
		text-align: center;

		&::after {
			content: '';
			@include absolute(bottom -1rem left 0);
			@include size(0, .5rem);
			background-color: var(--red);
			transition: .5s width;
		}

		&:hover {
			&::after {
				width: 100%;
			}
		}
	}

	&__switcher {
		display: flex;
		margin-block: var(--sp-3x);

		&-item {
			margin-inline-end: var(--sp-2x);

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__social {
		&-list {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&-item {
			margin-inline-end: var(--sp-5x);

			&:last-child {
				margin-right: 0;
			}
		}
	}
}