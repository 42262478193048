.modal-slider {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	@include size(100%);
	align-items: center;
	justify-content: center;
	@include z-index('satellite');
	background-color: rgba(15, 15, 39, .8);

	&.open {
		display: flex;
	}

	&__wrapper {
		width: 88%;
		aspect-ratio: 16 / 9;//sass-lint:disable-line no-misspelled-properties
		position: relative;

		@include bp('medium') {
			max-width: 60rem;
		}

		@include bp('large') {
			max-width: 90rem;
		}
	}

	&__btn {
		@include absolute(top -3rem right 0);

		&::after {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(45deg);
		}

		&::before {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(-45deg);
		}

		&-text {
			padding-right: var(--sp-3x);
		}
	}

	&__container {
		width: 100%;
		height: 100%;
	}

	&__image {
		aspect-ratio: 1; //sass-lint:disable-line no-misspelled-properties
		object-fit: cover;
	}

	&__figure {
		height: 100%;
	}
}