.card-rows {
	padding-block-end: var(--sp-10x);
	position: relative;
	@extend %fix-safari-overflow-scroll;

	&__container {
		display: flex;
		justify-content: center;
		@extend %grid;
		position: relative;
		@include z-index('sidewalk');
		padding-block: var(--sp-5x);
		background-color: var(--white);

		@include bp('medium') {
			justify-content: space-between;
			align-items: center
		}

		@include bp('extra-large') {
			margin-block-end: var(--sp-5x);
			background-color: transparent;
			padding-block: 0;
		}
	}

	&__link {
		&--mobile {
			display: flex;
			justify-content: center;
			margin-block-start: var(--sp-3x);

			@include bp('medium') {
				display: none;
			}
		}

		&--desktop {
			display: none;

			@include bp('medium') {
				display: block;
			}
		}
	}

	&__figure {
		overflow: hidden;
		background-color: var(--red);

		//fix for safari < 15
		@supports not (aspect-ratio: 5 / 4) {
			height: 100%;
		}

		&:hover {
			@extend %overlay;

			&::after {
				@include rotate(-8);
				transform-origin: 0 0;
			}

			.card-rows__image {
				@include rotate(-8);
				transform-origin: 0 0;
				transition: .3s transform ease(out-sine)
			}

			+.card-rows__hovered {
				opacity: 1;
				transition: .3s opacity ease(out-sine)
			}
		}
	}

	&__image {
		object-fit: cover;
		aspect-ratio: 5 / 4; //sass-lint:disable-line no-misspelled-properties
	}

	&__hovered {
		opacity: 0;
		pointer-events: none;
		@extend %position-center;

		&-image {
			margin-block-end: var(--sp-1x);
		}
	}

	.swiper {
		&-wrapper {
			transition-timing-function: linear;
		}

		&-slide {
			overflow: hidden;

			//fix for safari < 15
			@supports not (aspect-ratio: 5 / 4) {
				height: auto;
			}

		}
	}
}