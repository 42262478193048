.modal-video {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	@include size(100%);
	align-items: center;
	justify-content: center;
	@include z-index('satellite');
	background-color: rgba(15, 15, 39, .8);

	&.open {
		display: flex;
	}

	&__container {
		width: 88%;
		position: relative;

		@include bp('medium') {
			max-width: 60rem;
		}

		@include bp('large') {
			max-width: 90rem;
		}
	}

	&__iframe {
		position: absolute;
		top: 0;
		left: 0;
		@include size(100%);

		&-container {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
		}
	}

	&__btn {
		@include absolute(top -3rem right 0);

		&::after {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(45deg);
		}

		&::before {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(-45deg);
		}

		&-text {
			padding-right: var(--sp-3x);
		}
	}
}