*,
*::before,
*::after {
	box-sizing: border-box
}

input,
button,
select,
textarea {
	@extend %outline-a11y;

}

[tabindex] {
	@extend %outline-a11y;
}

a {
	@extend %outline-a11y;
	color: inherit;
	text-decoration: none;
}

//remove default styles
input,
button,
select,
textarea {
	border: 0;
	outline: 0;
	background: transparent;
	margin: 0;
	padding: 0;
	font-family: inherit;
	font-size: inherit;
	appearance: none;
}

input {

	&[type=radio],
	&[type=checkbox],
	&[type=file] {
		@extend %hide-element;
	}

	&[type=search] {

		//remove X from Chrome
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}
}

strong {
	font-weight: var(--fw-bold);
}

em {
	font-style: italic;
}

small,
sub,
sup {
	font-size: .83em //sass-lint:disable-line property-units
}

sub {
	vertical-align: sub
}

sup {
	vertical-align: super
}

// style when selected mouse
::selection {
	background-color: var(--selection);
	color: var(--white);
}

//layout
body {
	@extend %body-layout;
}

.wrapper {
	@extend %wrapper-layout;
	overflow: hidden;
}

.footer {
	@extend %footer-layout;
}

.arrow {
	display: inline-flex;
	align-items: center;
	text-transform: uppercase;

	&:hover {
		.arrow__grow {
			transform: translateX(1rem);
			transition: .3s transform ease(out-sine);
		}
	}

	&__svg {
		margin-inline-start: var(--sp-1x);
		@include size(2.4rem, 1.5rem);
	}
}

.swiper {
	@include size(100%);

	.slider-grid &,
	.slider-thumbs &,
	.modal-slider & {
		&-button {
			&-prev {
				left: auto;
				right: 5.5rem;
			}

			&-next {
				right: 0;
			}
		}

		&-pagination {
			width: calc(100% - 10.5rem);
			position: static;
			padding-inline: var(--sp-2x);
		}
	}

	// .slider-grid & {
	// 	&-button {
	// 		&-prev {
	// 			display: none;

	// 			@include bp('extra-large') {
	// 				display: block;
	// 			}
	// 		}

	// 		&-next {
	// 			display: none;

	// 			@include bp('extra-large') {
	// 				display: block;
	// 			}
	// 		}
	// 	}

	// 	&-pagination {
	// 		display: none;

	// 		@include bp('extra-large') {
	// 			display: block;
	// 		}
	// 	}
	// }
}

.slider {
	&__nav {
		@include absolute(right 0 bottom -5rem);
		@include size(21rem, 5rem);
		@include z-index('sidewalk');
		background-color: var(--blue);
		color: var(--white);
		align-items: center;

		.slider-thumbs & {
			display: flex;
			bottom: 1rem;
		}

		.slider-grid &,
		.modal-slider & {
			display: flex;
		}

		@include bp('extra-large') {
			.slider-grid & {
				display: flex;
			}
			.slider-grid.slider-grid--gray & {
				display: none;
			}

			.slider-grid.slider-grid--gray.slider-grid--full-width & {
				display: flex;
			}
		}
	}

	&__button {
		background-color: var(--white);
		@include size(5rem);
		top: 0;
		margin-top: 0;

		&::before,
		&::after {
			display: none;
		}
	}

	&__icon {
		.swiper-button-prev & {
			@include scaleX(-1)
		}
	}
}