.intro-video {
	position: fixed;
	top: 0;
	left: 0;
	@include size(100%);
	@extend %flex-center;
	@include z-index('satellite');
	background-color: rgba(15, 15, 39, .8);

	&.close {
		display: none;
	}

	&__container {
		width: 88%;
		position: relative;

		@include bp('medium') {
			max-width: 60rem;
		}

		@include bp('large') {
			max-width: 90rem;
		}
	}

	&__btn {
		@include absolute(top -3rem right 0);

		&::after {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(45deg);
		}

		&::before {
			content: '';
			@include absolute(top .5rem right 0);
			@include size(2rem, .2rem);
			background-color: var(--red);
			transform: rotate(-45deg);
		}

		&-text {
			padding-right: var(--sp-3x);
		}
	}
}