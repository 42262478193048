.card-info {
	@extend %grid;
	background-color: var(--white);
	position: relative;
	padding-block: var(--sp-5x) var(--sp-30x);

	@include bp('medium') {
		padding-block-start: var(--sp-20x);
	}

	&::before,
	&::after {
		@include bp('medium') {
			content: '';
			background-color: var(--red);
			@include absolute(top 0 left 0);
			@include z-index('bungalow')
		}
	}

	&::before {
		@include bp('medium') {
			@include size(100%, 10rem);
			clip-path: polygon(0 0, 100% 100%, 100% 0);
		}

		@include bp('extra-large') {
			height: 20rem;
		}
	}

	&::after {
		@include size(12.5rem, 100%);
		clip-path: polygon(0 0, 6.5rem 0, 0 40%);

		@include bp('extra-large') {
			clip-path: polygon(0 0, 12.5rem 0, 2rem 100%, 0 100%);
		}
	}

	+.card-rows {
		top: -9rem
	}

	&__heading {
		margin-block-end: var(--sp-6x);
		position: relative;
		@include z-index('tower')
	}

	&__posheading {
		display: block;
		margin-block-start: var(--sp-1x);
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: var(--sp-2x);
		row-gap: var(--sp-5x); //sass-lint:disable-line no-misspelled-properties
		margin-block-end: var(--sp-9x);

		@include bp('medium') {
			padding-inline: 6%;
			column-gap: var(--sp-8x);
		}

		@include bp('extra-large') {
			column-gap: var(--sp-5x);
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__container {
		position: relative;
		padding-block-start: var(--sp-3x);

		@include bp('medium') {
			margin-block-end: var(--sp-3x);
			padding-block-end: var(--sp-3x);
			padding-left: var(--sp-1x);
			padding-right: var(--sp-1x);
		}

		&::before,
		&::after {
			@include bp('medium') {
				content: '';
				@include size(100%);
				@include absolute(top 0 left 0);
			}
		}

		&::before {
			@include bp('medium') {
				background-color: var(--red);
			}
		}

		&::after {
			@include bp('medium') {
				background-color: var(--white);
				@include rotate(8)
			}
		}

	}

	&__digit,
	&__title {
		display: block;
		position: relative;
		@include z-index('sidewalk')
	}

	&__digit {
		visibility: hidden;
	}

}