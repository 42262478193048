.list-images {
	@extend %grid;

	@include bp('extra-large') {
		padding-right: 0;
		display: flex;
		align-items: flex-start;
	}

	&__column {

		&:first-child {
			@include bp('extra-large') {
				flex: 1;
				padding-inline-end: var(--sp-6x);
			}

		}

		&--desktop {
			display: none;

			@include bp('extra-large') {
				display: block;
				background-color: var(--red);
				flex: 1;
				max-width: 60rem;
				max-height: 60rem;
			}
		}
	}

	&__article {
		margin-block-end: var(--sp-4x);
		padding-block-end: var(--sp-4x);
		border-bottom: .1rem solid var(--blue-10);
		position: relative;

		@include bp('medium') {
			display: flex;
			align-items: center;
		}

		&:hover {
			.list-images__icon {
				&-circle {
					display: block;
				}

				&-path {
					fill: var(--white)
				}
			}
		}
	}

	&__heading {
		margin-block-end: var(--sp-7x);
	}

	&__figure {
		&--mobile {
			margin-block-end: var(--sp-2x);

			@include bp('medium') {
				padding-inline-end: var(--sp-6x);
				margin-bottom: 0;
			}

			@include bp('extra-large') {
				display: none;
			}
		}

		&--desktop {
			height: 100%;
		}
	}

	&__image {
		object-fit: cover;
		aspect-ratio: 1 / 1; //sass-lint:disable-line no-misspelled-properties

		.list-images__figure--mobile & {

			@include bp('medium') {
				max-width: 14rem;
			}
		}

		.list-images__figure--desktop & {
			transition: .3s transform ease(out-sine);
		}
	}

	&__copy {
		display: block;
		margin-block-end: var(--sp-1x);

		@include bp('medium') {
			flex: 1;
			margin-bottom: 0;
			padding-inline-end: var(--sp-3x);
		}
	}

	&__icon {
		display: none;

		@include bp('medium') {
			display: block;
		}

		&-circle {
			display: none;
		}

		&-path {
			fill: var(--red)
		}
	}

	&__url {
		display: flex;
		align-items: center;
		margin-block-end: var(--sp-7x);

		&--mobile {
			margin-bottom: 0;

			@include bp('medium') {
				display: none;
			}
		}
	}
}