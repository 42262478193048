//CSS vars
//sass-lint:disable no-invalid-hex
:root {
	--base-font: 62.5%;
	--font-sora: 'Sora';
	--font-neue: 'TT Firs Neue';
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-demi: 600;
	--fw-bold: 700;
	--white: #fff;
	--white-50: #ffffff80;
	--gray: #fafafa;
	--red: #f03647;
	--blue: #100f28;
	--blue-10: #100f281a;
	--blue-20: #100f2833;
	--black: #000;
	--black-00: #00000000;
	--black-50: #00000080;
	--black-60: #00000099;
	--video-controls-overlay: linear-gradient(0deg, var(--black-60) 0%, var(--black-50) 60%, var(--black-00) 100%);
	--selection: var(--red);
	--sp-default: 1rem;
	--sp-1x: calc(var(--sp-default) * 1);
	--sp-2x: calc(var(--sp-default) * 2);
	--sp-3x: calc(var(--sp-default) * 3);
	--sp-4x: calc(var(--sp-default) * 4);
	--sp-5x: calc(var(--sp-default) * 5);
	--sp-6x: calc(var(--sp-default) * 6);
	--sp-7x: calc(var(--sp-default) * 7);
	--sp-8x: calc(var(--sp-default) * 8);
	--sp-9x: calc(var(--sp-default) * 9);
	--sp-10x: calc(var(--sp-default) * 10);
	--sp-11x: calc(var(--sp-default) * 11);
	--sp-12x: calc(var(--sp-default) * 12);
	--sp-13x: calc(var(--sp-default) * 13);
	--sp-14x: calc(var(--sp-default) * 14);
	--sp-15x: calc(var(--sp-default) * 15);
	--sp-16x: calc(var(--sp-default) * 16);
	--sp-17x: calc(var(--sp-default) * 17);
	--sp-18x: calc(var(--sp-default) * 18);
	--sp-19x: calc(var(--sp-default) * 19);
	--sp-20x: calc(var(--sp-default) * 20);
	--sp-21x: calc(var(--sp-default) * 21);
	--sp-22x: calc(var(--sp-default) * 22);
	--sp-23x: calc(var(--sp-default) * 23);
	--sp-24x: calc(var(--sp-default) * 24);
	--sp-25x: calc(var(--sp-default) * 25);
	--sp-26x: calc(var(--sp-default) * 26);
	--sp-27x: calc(var(--sp-default) * 27);
	--sp-28x: calc(var(--sp-default) * 28);
	--sp-29x: calc(var(--sp-default) * 29);
	--sp-30x: calc(var(--sp-default) * 30);
}

.website-spi {
	--red: #3955a0;
}

//sass-lint:enable

//breakpoint vars
$extra-small: 22.5em; //360px
$small: 36em; //576px
$medium: 48em; //768px
$large: 64em; //1024px
$extra-large: 75em; //1200px
$wide: 90em; //1440px
$extra-wide: 100em; //1600px

//breakpoints
$breakpoints: ( //types
	'extra-small': $extra-small,
	'small': $small,
	'medium': $medium,
	'large': $large,
	'extra-large': $extra-large,
	'wide': $wide,
	'extra-wide': $extra-wide //finish types
);

// Caching current breakpoint
// Not meant to be manually edited
$default-breakpoint: root;
$current-breakpoint: $default-breakpoint;

// Caching existing placeholders
// Not meant to be manually edited
$placeholders: ();

//effects
$ease: ( // list from https://css-tricks.com/snippets/sass/easing-map-get-function/
	in-quad: cubic-bezier(.55, .085, .68, .53),
	in-cubic: cubic-bezier(.55, .055, .675, .19),
	in-quart: cubic-bezier(.895, .03, .685, .22),
	in-quint: cubic-bezier(.755, .05, .855, .06),
	in-sine: cubic-bezier(.47, 0, .745, .715),
	in-expo: cubic-bezier(.95, .05, .795, .035),
	in-circ: cubic-bezier(.6, .04, .98, .335),
	in-back: cubic-bezier(.6, -.28, .735, .045),
	out-quad: cubic-bezier(.25, .46, .45, .94),
	out-cubic: cubic-bezier(.215, .61, .355, 1),
	out-quart: cubic-bezier(.165, .84, .44, 1),
	out-quint: cubic-bezier(.23, 1, .32, 1),
	out-sine: cubic-bezier(.39, .575, .565, 1),
	out-expo: cubic-bezier(.19, 1, .22, 1),
	out-circ: cubic-bezier(.075, .82, .165, 1),
	out-back: cubic-bezier(.175, .885, .32, 1.275),
	in-out-quad: cubic-bezier(.455, .03, .515, .955),
	in-out-cubic: cubic-bezier(.645, .045, .355, 1),
	in-out-quart: cubic-bezier(.77, 0, .175, 1),
	in-out-quint: cubic-bezier(.86, 0, .07, 1),
	in-out-sine: cubic-bezier(.445, .05, .55, .95),
	in-out-expo: cubic-bezier(1, 0, 0, 1),
	in-out-circ: cubic-bezier(.785, .135, .15, .86),
	in-out-back: cubic-bezier(.68, -.55, .265, 1.55) // easing customs
);

//z-index
$z-index: ( //values
	'pothole': -1,
	'basement': 0,
	'sidewalk': 1,
	'bungalow': 40,
	'bungalow-chimney': 50,
	'house': 60,
	'tower': 70,
	'skyscrapper': 80,
	'sky': 90,
	'satellite': 100 //finish z-index
);
