.video-detail {
	@extend %grid;

	@include bp('extra-large') {
		display: flex;
	}

	&__heading {
		margin-block-end: var(--sp-2x);
		flex-basis: 100%;
	}

	&__column {
		&--small {
			margin-block-end: var(--sp-5x);
			padding-block: var(--sp-4x);

			@include bp('extra-large') {
				flex-basis: 40rem;
				margin-bottom: 0;
				padding-block: var(--sp-8x);
				padding-inline: var(--sp-5x);
				background-color: var(--gray);
			}
		}

		&--large {
			@include bp('extra-large') {
				max-width: calc(100% - 40rem);
				padding-block-start: var(--sp-8x);
				padding-inline-start: var(--sp-6x);
			}
		}
	}

	&__row {
		@extend %flex-wrap;

		@include bp('extra-large') {
			display: block;
		}

		&:first-of-type {
			margin-block-end: var(--sp-6x);
		}
	}

	&__figure {
		margin-block-end: var(--sp-5x);
	}

	&__list {
		margin-block-end: var(--sp-2x);

		.video-detail__row:last-of-type & {
			flex-basis: 50%;

			@include bp('extra-large') {
				flex-basis: auto;
			}
		}

		&:first-child {
			margin-inline-end: var(--sp-10x);

			@include bp('extra-large') {
				margin-right: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&--logos {
			.video-detail__row:last-of-type & {
				flex-basis: 100%;
				@include bp('medium') {
					flex-basis: auto;
				}
			}
		}
	}

	&__sublist {
		@extend %flex-wrap;
	}

	&__subitem {
		flex: 0 100%;
		margin-inline-end: calc(var(--sp-1x) /2);

		.video-detail__list--logos & {
			flex-basis: calc((100% / 3) - (var(--sp-1x) /2));
			@extend %flex-center;
			margin-block-end: var(--sp-2x);

		}
	}

	&__link {
		display: flex;
		align-items: center;
		margin-block-end: var(--sp-3x);

		@include bp('extra-large') {
			display: none
		}

		.arrow__svg {
			@include scaleX(-1);
			margin-inline-end: var(--sp-1x);
		}

		.video-detail__column--large & {
			&:first-of-type {
				display: none;

				@include bp('extra-large') {
					display: flex;
				}
			}

			&:last-of-type {
				display: flex;
			}
		}
	}

	&__image {
		.video-detail__list--logos & {
			max-width: 9rem;
			max-height: 7rem;
			object-fit: contain;
		}
	}

	&__term {
		.video-detail__list--logos & {
			margin-bottom: var(--sp-1x);
		}
	}
}