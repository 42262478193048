.jumbotron {
	position: relative;
	@include size(100%, 40rem);
	margin-block-end: var(--sp-6x);
	@extend %clip-path-jumbotron-hero;

	&::before,
	&::after {
		background-color: var(--white);
	}

	@include bp('medium') {
		height: 65rem;
	}

	&__picture {
		@include absolute(top 0 left 0);
		@include z-index('pothole');
		@include size(100%, 40rem);
		@extend %overlay;

		@include bp('medium') {
			height: 65rem;
		}
	}

	&__image {
		object-fit: cover;
		object-position: top;
		aspect-ratio: 1 / 1; //sass-lint:disable-line no-misspelled-properties

		@include bp('extra-large') {
			aspect-ratio: 144 / 65; //sass-lint:disable-line no-misspelled-properties
		}
	}

	&__heading {
		@extend %grid-small;
		display: flex;
		align-items: center;
		height: 100%;
	}
}