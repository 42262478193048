.header {
	@include fixed(top 0 left 0);
	width: 100%;
	transition: .5s background-color ease(out-sine);
	padding-block: calc(var(--sp-1x) * 2.5);
	@extend %grid;
	@include z-index('satellite');
	display: flex;
	justify-content: space-between;
	align-items: center;

	&--colored {
		background-color: var(--blue);
	}

	&__column {
		display: flex;
	}

	&__image {
		width: 100%;
	}

	&__switcher {
		margin-right: var(--sp-5x);

		.header--open & {
			display: none;
		}

		&-item {
			&:first-child {
				border-bottom: .1rem solid var(--red);
				margin-block-end: var(--sp-1x);
				padding-block-end: var(--sp-1x);
			}

			&.is-active {
				color: var(--red);
			}
		}
	}

	&__button {

		&:not(.header__button--active) {
			&:hover {
				.header__hamburger-line {
					&:nth-child(2) {
						width: 75%;
					}

					&:last-child {
						width: 40%;
					}
				}
			}
		}

		&--active {

			&:hover {
				.header__hamburger {

					&::before {
						border-top-color: var(--red);
						border-right-color: var(--red);
						border-bottom-color: var(--red);
						transition: border-top-color .15s linear, border-right-color .15s linear .1s, border-bottom-color .15s linear .2s
					}

					&::after {
						border-top: .2rem solid var(--red);
						border-left-width: .2rem;
						border-right-width: .2rem;
						@include rotate(270);
						transition: transform .4s linear 0s, border-left-width 0s linear .35s, transform .4s linear 0s;
					}
				}
			}

			.header__hamburger {
				@include size(4.8rem);
				padding: var(--sp-1x);
				position: relative;

				&::before,
				&::after {
					content: '';
					@include absolute(top 0 left 0);
					@include size(100%);
					@include border-radius(50%);
				}

				&::before {
					border: .2rem solid transparent
				}

				&::after {
					border: 0 solid transparent
				}

				&-line {
					margin-bottom: 0;

					&:first-child {
						@include rotate(45);
						transform-origin: 0 .8rem;
					}

					&:nth-child(2) {
						display: none;
					}

					&:last-child {
						@include rotate(135);
						transform-origin: 1.2rem .6rem;
					}
				}
			}
		}
	}

	&__hamburger {
		margin-block-end: var(--sp-1x);
		@include scaleX(-1);

		&-line {
			transform-origin: 0 0;
			display: block;
			background-color: var(--red);
			@include size(100%, .3rem);
			transition: .5s;
			margin-block-end: calc(var(--sp-1x) * .75);

			&:last-child {
				margin-bottom: 0
			}

		}
	}
}
