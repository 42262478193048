html {
	font-size: var(--base-font);
}

body {
	color: var(--blue);
}

//include only the following properties:
// font-family
// font-size
// font-weight
// line-height
// text-decoration
// text-align
// text-transform
// color


// default typography

.alpha {
	font-size: 5rem;
	line-height: line-height(6, 5);
	font-weight: var(--fw-regular);
	font-family: var(--font-neue);

	@include bp('medium') {
		line-height: normal;
		font-size: 7.5rem;
	}
}

.beta,
.gamma {
	font-size: 4rem;
	line-height: line-height(5, 4);
	font-family: var(--font-neue);

	@include bp('extra-large') {
		font-size: 7rem;
		line-height: line-height(8, 7);
	}
}

.beta {
	font-weight: var(--fw-demi);
}

.gamma {
	font-weight: var(--fw-medium);
}

.delta,
.epsilon {
	font-size: 3.8rem;
	line-height: line-height(4.8, 3.8);
	font-family: var(--font-neue);

	@include bp('extra-large') {
		font-size: 6rem;
		line-height: line-height(7, 6);
	}
}

.delta {
	font-weight: var(--fw-demi);
}

.epsilon {
	font-weight: var(--fw-regular);
}

.zeta {
	font-size: 3.4rem;
	line-height: line-height(4.4, 3.4);
	font-family: var(--font-neue);
	font-weight: var(--fw-bold);

	@include bp('extra-large') {
		font-size: 4rem;
		line-height: line-height(5, 4);
	}
}

.eta,
.theta {
	font-size: 3rem;
	line-height: line-height(4, 3);
	font-family: var(--font-neue);
}

.eta {
	font-weight: var(--fw-demi);
}

.theta {
	font-weight: var(--fw-medium);
}

.iota {
	font-size: 1.6rem;
	line-height: line-height(2.1, 1.6);
	font-family: var(--font-neue);
	font-weight: var(--fw-medium);

	@include bp('extra-large') {
		font-size: 2rem;
		line-height: line-height(2.5, 2);
	}
}

.lambda {
	font-size: 1.4rem;
	line-height: line-height(2, 1.4);
	font-family: var(--font-sora);

	@include bp('extra-large') {
		font-size: 1.6rem;
		line-height: line-height(2.6, 1.6);
	}

	&--light {
		font-weight: var(--fw-light);
	}

	&--bold {
		font-weight: var(--fw-medium);
	}
}

// blocks sort by order A-Z

.card-images {

	&__heading {
		color: var(--red);
	}

	&__item {

		&.is-active {
			//sass-lint:disable-block no-misspelled-properties no-vendor-prefixes
			text-transform: uppercase;
			font-weight: var(--fw-bold);
			text-decoration: underline var(--red) .1rem;
			@supports not (text-decoration: underline red 1px) {
				-webkit-text-decoration: underline var(--red);
				text-decoration-thickness: .1rem;
			}
			text-decoration-skip-ink: none;
			text-underline-offset: .5rem;
		}
	}

	&__caption {

		@extend %typography-body-large;
		font-weight: var(--fw-medium);

		&-gapless {
			color: var(--white);
			text-align: center;
		}
	}

	&__figcaption {
		color: var(--white);
		text-align: center;
	}

	&__button {
		color: var(--red);

		.arrow__svg {
			color: var(--blue);
		}
	}
}

.card-info {
	text-align: center;

	&__posheading {
		color: var(--red)
	}

	&__title {
		color: var(--red);
		text-transform: uppercase;
		font-family: var(--font-sora)
	}

	&__copy {
		@extend %typography-body-x-large;
	}

	&__link {
		color: var(--red);

		.arrow__svg {
			color: var(--blue);
		}
	}
}

.card-rows {

	&__heading {
		color: var(--red);
	}

	&__link {
		.arrow__svg {
			color: var(--red);
		}
	}

	&__caption {
		color: var(--white);
		text-align: center;
	}
}

.footer {
	color: var(--white);
	font-family: var(--font-sora);

	&__text {
		font-size: 1.5rem;
		line-height: line-height(2.8, 1.5);
		font-weight: var(--fw-bold);
		color: var(--red);
		text-transform: uppercase;
	}

	&__copy,
	&__item {
		font-size: 1.2rem;
		font-weight: var(--fw-medium);
	}

	&__item {
		&--highlight {
			font-weight: var(--fw-bold);
			color: var(--red);
		}
	}
}

.header {

	&__column {
		font-size: 1.2rem;
		line-height: line-height(1.6, 1.2);
		font-family: var(--font-neue);
		font-weight: var(--fw-medium);
		text-transform: uppercase;
		color: var(--white);
		@include font-tracking(313.5);
	}

	&__hamburger {
		&-copy {
			font-size: 1.2rem;
			line-height: line-height(1.6, 1.2);
			font-family: var(--font-neue);
			font-weight: var(--fw-medium);
			text-transform: uppercase;
			color: var(--white);
			@include font-tracking(313.5);

			.header__button--active & {
				font-size: 1.1rem;
				line-height: line-height(1.4, 1.1);
				@include font-tracking(120);
			}
		}
	}
}

.intro-video {
	&__btn {
		&-text {
			font-size: 1rem;
			font-family: var(--font-neue);
			font-weight: var(--fw-medium);
			text-transform: uppercase;
			color: var(--white);
		}
	}
}

.jumbotron {

	&__heading {
		color: var(--white);
	}
}

.list-images {

	&__heading {
		color: var(--red);
	}

	&__copy {
		@extend %typography-body-x-large;
		text-align: justify;
	}

	&__url {
		color: var(--red);

		.arrow__svg {
			color: var(--blue);
		}
	}
}

.list-numbers {

	&__heading {
		color: var(--red);
	}

	&__title {
		color: var(--red);
		text-transform: uppercase;
	}
}

.map {

	&__heading {
		color: var(--red);
	}

	&__info {

		&--link {
			text-decoration: underline;
		}
	}
}

.modal-video,
.modal-slider {
	&__btn {
		&-text {
			font-size: 1rem;
			font-family: var(--font-neue);
			font-weight: var(--fw-medium);
			text-transform: uppercase;
			color: var(--white);
		}
	}
}

.nav {

	.website-spi & {
		.nav {
			&__item {
				text-transform: initial;
			}
		}
	}

	&__item {
		color: var(--white);
		text-transform: uppercase;

		&.is-active {
			color: var(--red);
		}
	}

	&__switcher {
		&-item {
			color: var(--white);

			&.is-active {
				color: var(--red);
			}
		}
	}

	&__social {
		&-item {
			color: var(--red)
		}
	}
}

.rte {

	&__container {
		p {
			@extend %typography-body-large;
		}

		ul {
			@extend %typography-body-x-large;
			color: var(--blue);
		}

		blockquote {
			@extend .theta;
			color: var(--blue);
		}

		strong {
			color: var(--blue);
		}
	}

	&__posheading {
		color: var(--blue)
	}


	&__date {
		font-size: 1.8rem;
		line-height: line-height(3, 1.8);
		font-family: var(--font-sora);
		font-weight: var(--fw-light);
		color: var(--red);
	}

	&__source {
		font-size: 1.4rem;
		line-height: line-height(3, 1.4);
		font-family: var(--font-sora);
		text-transform: uppercase;
		font-weight: var(--fw-regular);
		text-align: right;
	}

	&__link {
		&--reverse {
			.rte__link-copy {
				color: var(--red);
				font-weight: var(--fw-bold)
			}
		}
	}
}

.modal-slider {
	.swiper {
		&-pagination {
			@extend .lambda;

			&-current {
				@extend .lambda--bold;
				color: var(--red);
			}
		}
	}
}

.slider-grid {

	&__heading,
	&__title {
		color: var(--red);
	}

	&__subtitle {
		font-weight: var(--fw-bold);
	}

	&__item {
		&.is-active {
			//sass-lint:disable-block no-misspelled-properties no-vendor-prefixes
			text-transform: uppercase;
			font-weight: var(--fw-bold);
			text-decoration: underline var(--red) .1rem;
			@supports not (text-decoration: underline red 1px) {
				-webkit-text-decoration: underline var(--red);
				text-decoration-thickness: .1rem;
			}
			text-decoration-skip-ink: none;
			text-underline-offset: .5rem;
		}
	}

	&__caption {
		color: var(--red);
		text-align: center;


		&-copy {
			line-height: normal
		}

		&-small {
			font-family: var(--font-sora);
			font-size: 1.2rem;
			line-height: line-height(14, 12);
			color: var(--white)
		}
	}

	&__copy {
		text-align: justify;
	}

	.swiper {
		&-pagination {
			@extend .lambda;

			&-current {
				@extend .lambda--bold;
				color: var(--red);
			}
		}
	}
}

.slider-hero {

	&__heading {
		color: var(--white);
	}

	&__link {
		color: var(--red);

		.arrow__svg {
			color: var(--white);
		}
	}

	&__btn {
		&-text {
			color: var(--white);
		}
	}
}

.slider-thumbs {
	&__heading {
		color: var(--red);
	}

	&__item {
		&.is-active {
			//sass-lint:disable-block no-misspelled-properties no-vendor-prefixes
			text-transform: uppercase;
			font-weight: var(--fw-bold);
			text-decoration: underline var(--red) .1rem;
			@supports not (text-decoration: underline red 1px) {
				-webkit-text-decoration: underline var(--red);
				text-decoration-thickness: .1rem;
			}
			text-decoration-skip-ink: none;
			text-underline-offset: .5rem;
		}
	}

	.swiper {
		&-pagination {
			@extend .lambda;

			&-current {
				@extend .lambda--bold;
				color: var(--red);
			}
		}
	}
}

.social-media {

	&__title {
		color: var(--white);
	}

	&__heading {
		color: var(--red)
	}

	&__copy {
		@extend %typography-body-large;
		text-align: justify;

	}

	&__item {
		color: var(--red);

		.arrow__svg {
			color: var(--blue);
		}
	}

	&__url {

		.arrow__svg {
			color: var(--white);
		}
	}
}

.video-detail {

	&__heading,
	&__term {
		color: var(--red)
	}

	&__column {
		&--large {
			@extend %typography-body-large;

		}
	}

	&__copy {
		text-align: justify;
	}
}
