.slider-grid {
	@extend %grid-small;
	@extend %flex-wrap;
	padding-block: var(--sp-10x);
	padding-right: 0;
	position: relative;

	@include bp('extra-large') {
		padding-inline-end: 5%;
	}

	@include bp('wide') {
		padding-inline-end: 10%;
	}

	.website-spi & {
		.swiper:not(.js-slider-cast) {
			height: auto;
		}

	}

	&__container.swiper-off {
		&.is-mobile {
			.swiper-wrapper {
				justify-content: start;
			}

			.swiper-slide {
				width: 50%;
			}
		}
		.swiper-wrapper {
			justify-content: end;
		}

		.swiper-slide {
			width: 25%;

			// @include bp('medium') {
			// 	width: 50%;
			// }
		}

		.slider__nav {
			display: none;
		}
	}

	.video-detail & {
		padding-inline: 0;
	}

	&.slider-grid--full-width {
		@include bp('extra-large') {
			padding-inline-end: 16%;
		}
		@include bp('wide') {
			padding-inline-end: 16%;
		}
		.swiper {
			&-slide {
				@include bp('extra-large') {
					height: 100%;
				}
			}
		}
	}

	&--gray {
		background-color: var(--gray);
	}

	&__list {
		@extend %list-overflow-scroll;

		@include bp('extra-large') {
			flex-wrap: wrap;
		}
	}

	&__item {
		margin-inline-end: var(--sp-4x);
		flex-shrink: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	&__heading {
		margin-block-end: var(--sp-4x);
		flex-basis: 100%;
	}

	&__subtitle {
		margin-block-end: var(--sp-3x);
	}

	&__container {
		width: 100%;
		margin-block-end: var(--sp-8x);
		position: relative;

		@include bp('extra-large') {
			@include size(72rem, 90em);

			.website-spi & {
				@include size(72rem, auto);
			}

			margin-bottom: 0;
			margin-inline: 0 var(--sp-3x);

			.video-detail & {
				margin-block-end: var(--sp-3x);
				@include size(100%, auto);
			}

			.slider-grid--full-width & {
				margin-block-end: 0;
				@include size(100%, auto);
			}
		}
	}

	&__article {
		flex: 1;
		padding-inline-end: 6%;

		@include bp('extra-large') {
			padding-inline-end: 0
		}
	}

	&__figure {
		background-color: var(--red);
		height: 100%;
		overflow: hidden;
		position: relative;

		&.is-active {

			.slider-grid__image {
				filter: grayscale(0);
			}

			&::before {
				content: '';
				@include size(100%, .5rem);
				@include absolute(bottom 0 left 0);
				@include z-index('sidewalk');
				background-color: var(--red);
			}
		}

		&:hover {
			@extend %overlay;

			&::before {
				display: none;
			}

			&::after {
				@include rotate(-8);
				transform-origin: 0 0;
				@include size(101%);
			}

			.slider-grid__caption {
				opacity: 1;
				transition: .3s opacity ease(out-sine)
			}

			.slider-grid__image {
				@include rotate(-8);
				transform-origin: 0 0;
				transition: .3s transform ease(out-sine)
			}
		}
	}

	&__image {
		filter: grayscale(1);
		aspect-ratio: 1; //sass-lint:disable-line no-misspelled-properties
		object-fit: cover;
		object-position: top center;
		@include size(101%); //fix bug safari
	}

	&__caption {
		opacity: 0;
		pointer-events: none;
		@extend %position-center;
		@include z-index('sidewalk');

		&-copy {
			display: block;
			margin-block-end: var(--sp-1x)
		}

		&-small {
			display: block;
		}
	}

	.swiper {
		&-slide {
			@include bp('extra-large') {
				height: 20%;
			}
			.video-detail & {
				@include bp('extra-large') {
					height: 100%;
				}
			}
		}

		// &-wrapper {
		// 	.video-detail & {
		// 		margin-block-end: var(--sp-2x);
		// 	}
		// }
	}
}