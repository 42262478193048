.video {
	position: relative;
	height: 100%;
	@include z-index('sidewalk');

	&-jumbotron {
		@include size(100%, 40rem);

		@include bp('medium') {
			height: 65rem;
		}

		@include bp('extra-large') {
			padding-inline-start: calc(8% - (var(--sp-1x) * 2.5));
			padding-inline-end: calc(8% - (var(--sp-1x) * 2.5));
		}

		&__figure {
			height: 100%;
		}

		&__image {
			object-fit: cover;
		}

		&--no-height {
			height: auto;
		}
	}

	&-is-playing {
		overflow: hidden;

		&:hover {

			.video__controls {

				&::after {
					@include translateY(0)
				}
			}
		}
	}

	&__player {
		object-fit: cover;
		aspect-ratio: 16 / 9; //sass-lint:disable-line no-misspelled-properties
		object-position: top;
	}

	&__cta {
		@extend %position-center;
		background-color: var(--red);
		@include size(10rem);
		@include border-radius(50%);

		.video__icons {
			width: 50%;
			margin-inline-start: 10%;
		}

		.video-is-playing & {
			display: none;
		}

		.slider-thumbs & {
			@include size(6rem);
			@extend %flex-center;
		}
	}

	&__controls {
		display: none;
		@include transform(translateY(100%) translateY(6rem));
		transition: .3s;

		&::after {
			content: '';
			background: var(--video-controls-overlay);
			@include absolute(bottom 0 left 0);
			@include size(100%, 8rem);
			@include transform(translateY(100%));
			transition: .3s;
		}

		.video-is-playing & {
			display: block;
		}

		.video-is-playing:hover & {
			@include translateY(0);
		}

		&-container {
			display: flex;
			align-items: center;
			@include absolute(bottom 2.5rem right 6%);
			@include z-index('sidewalk');

			@include bp('extra-large') {
				right: 8%;
			}
		}
	}

	&__button {
		@include size(3.2rem);
		margin-inline-end: var(--sp-2x);

		&--fullscreen {
			margin-inline-start: auto;
			@include size(2.4rem);
		}
	}

	&__icons {
		fill: var(--white);
	}

	&__seekbar {
		border: 0;
		appearance: none;
		@include size(100%, .5rem);
		@include absolute(bottom 0 left 0);
		@include z-index('sidewalk');

		&::-webkit-progress-bar {
			background-color: transparent;
		}

		&::-webkit-progress-value {
			background-color: var(--red);
		}

		&::-moz-progress-bar {
			background-color: var(--red);
		}
	}

	&__pause,
	&__sound {
		display: none;
	}

	&__sound {
		.video-has-sound & {
			display: block;
		}
	}

	&__mute {
		.video-has-sound & {
			display: none;
		}
	}

	&__pause {
		.video-is-playing & {
			display: block;
		}
	}

	&__play {
		.video-is-playing & {
			display: none;
		}
	}
}