.list-numbers {
	background-color: var(--gray);
	padding-block: var(--sp-10x);
	@extend %grid-smaller;
	position: relative;

	+.rte {
		padding-block-start: var(--sp-10x);
	}

	&__heading {
		margin-block-end: var(--sp-6x);
	}

	&__list {
		@extend %flex-wrap;
	}

	&__item {
		flex-basis: 50%;
		margin-block-end: var(--sp-8x);

		&:nth-child(2n+1) {
			padding-inline-end: var(--sp-1x);
		}
	}

	&__digit,
	&__title,
	&__copy {
		display: block;
	}

	&__digit {
		visibility: hidden;
	}
}