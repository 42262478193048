%flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

%flex-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

%flex-center-v {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

%flex-center-h {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-column-wrap {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

%flex-column-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

%flex-column-center-v {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
}

%flex-column-center-h {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
}

%flex-between {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

%flex-between-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

%flex-between-column {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column;
}

%flex-between-column-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

%flex-around {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

%flex-around-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

%flex-around-column {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column;
}

%flex-around-column-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
}


%position-center {
	@include position(absolute, top 50% left 50%);
	@include translate(-50%, -50%);
}

%position-center-v {
	@include position(absolute, top 50%);
	@include translate(0, -50%);
}

%position-center-h {
	@include position(absolute, left 50%);
	@include translate(-50%, 0);
}

%block-center {
	margin-inline: auto;
}

%hide-element {
	@include absolute(left -9999.9rem);
}

// a11y
%outline-a11y {
	html:not(.a11y) & {
		outline: 0;

		&:focus {
			outline: inherit;
		}
	}

	&:focus {
		.a11y & {
			outline: thin dotted var(--black);
		}
	}
}

// layout
%body-layout {
	@extend %flex-column;
	min-height: 100vh;
	overflow-x: hidden;
}

%wrapper-layout {
	flex: 1 0 auto; //FIX WEBKIT based browsers bug from letting these items shrink to smaller than their content's default minimum size.
}

%footer-layout {
	flex-shrink: 0;
}

%grid {
	padding-inline-start: 6%;
	padding-inline-end: 6%;

	@include bp('extra-large') {
		padding-inline-start: 8%;
		padding-inline-end: 8%;
	}
}

%grid-small {
	padding-inline-start: 6%;
	padding-inline-end: 6%;

	@include bp('extra-large') {
		padding-inline-start: 16%;
		padding-inline-end: 16%;
	}
}

%grid-smaller {
	padding-inline-start: 6%;
	padding-inline-end: 6%;

	@include bp('extra-large') {
		padding-inline-start: 21%;
		padding-inline-end: 21%;
	}
}

%grid-spacing-small {
	padding: 1.5rem;

	@include bp('medium') {
		padding-inline: 5.5rem;
	}

	@include bp('large') {
		padding-inline: 9rem;
	}
}

%typography-body-x-large {
	font-family: var(--font-sora);
	font-size: 2.2rem;
	line-height: line-height(3.8, 2.2);
	font-weight: var(--fw-medium);
}

%typography-body-large {
	font-family: var(--font-sora);
	font-size: 1.8rem;
	line-height: line-height(3, 1.8);
	font-weight: var(--fw-light);
}

%clip-path-jumbotron-hero {

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&::before {
		@include size(30%, 100%);
		top: 0;
		left: 0;
		clip-path: polygon(0 60%, 0% 100%, 2.5rem 100%);

		@include bp('medium') {
			clip-path: polygon(0 60%, 0% 100%, 6.5rem 100%);
		}

		@include bp('extra-large') {
			clip-path: polygon(0 0, 2rem 0, 12.5rem 100%, 0 100%);
		}
	}

	&::after {
		@include size(100%, 5rem);
		bottom: 0;
		left: 0;
		clip-path: polygon(100% 100%, 100% 0%, 0 100%);


		@include bp('medium') {
			height: 10rem;
		}

		@include bp('extra-large') {
			height: 20rem;
		}
	}
}

%overlay {
	&::after {
		content: '';
		background: var(--black-50);
		@include size(100%);
		@include absolute(top 0 left 0);
		pointer-events: none;
	}
}

%list-overflow-scroll {
	display: flex;
	margin-block-end: var(--sp-5x);
	padding-block-end: var(--sp-1x);
	overflow-x: auto;
	flex-basis: 100%;
}

//FIX safari 11+ - https://www.browserstack.com/guide/create-browser-specific-css
%fix-safari-overflow-scroll {
	@media (max-width: 992px) {
		@media not all and (min-resolution: .001dpcm) {
			@supports (-webkit-appearance: none) and (stroke-color: transparent) { 	//sass-lint:disable-line no-vendor-prefixes
				overflow: hidden;
			}
		}
	}
}