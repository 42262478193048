@use 'sass:color';
@use 'sass:math';

.shape {
	@include shape();

	.website-spi & {
		$default: #f03647;
		$new: #100f28;

		$hue: color.hue($new) - color.hue($default);
		$saturation: math.div(color.saturation($new), color.saturation($default));
		$lightness: math.div(color.lightness($new), color.lightness($default));

		filter: hue-rotate($hue) sat#{u}rate($saturation) brightness($lightness);
	}

	//transform Classes
	&--flip-x {
		@include scaleX(-1);

		&-rotate-80 {
			@include transform(scaleX(-1) rotate(80deg))
		}
	}

	&--flip-y {
		@include scaleY(-1);

		&-rotate-60-n {
			@include transform(scaleY(-1) rotate(-60deg))
		}

		&-rotate-300 {
			@include transform(scaleY(-1) rotate(300deg))
		}
	}

	&--scalable {
		@include scale(.6);

		@include bp('medium') {
			@include scale(.8)
		}

		@include bp('extra-large') {
			@include scale(1.3);
		}

		&-flip-x {
			@include transform(scale(1.5) scaleX(-1));

			@include bp('extra-large') {
				@include transform(scale(1.2) scaleX(-1));
			}
		}
	}

	&--rotate {

		&-45 {
			@include rotate(45)
		}

		&-90-n {
			@include rotate(-90);

			&-flip-y {
				@include transform(rotate(-90deg) scaleX(-1))
			}
		}

		&-220 {
			@include rotate(220)
		}
	}

	//element Classes

	&-nav {
		opacity: 0;

		.nav--open & {
			opacity: 1;
			@include transform(rotate(45deg) scale(1.4));
			bottom: 55%;
			right: -80rem;
		}
	}

	&-jumbotron {
		bottom: -35%;
		right: -40rem;
	}

	&-error-page {
		bottom: 0;
		right: -40rem;
	}

	&-social-media {
		@include shape(0);
		top: -10%;
		left: 100%;
	}

	&-list-numbers {

		&-1,
		&-2 {
			@include shape(2, 'medium', 'extra-large')
		}

		&-1 {
			@include bp('extra-large') {
				top: -15%;
				left: -44rem;
			}
		}

		&-2 {
			@include bp('extra-large') {
				bottom: -50%;
				right: -47rem;
			}
		}
	}

	&-video-detail {
		top: -50%;
		right: -78rem;
	}

	&-card-rows {
		@include shape(0);
		top: 60rem;
		left: 20rem
	}

	&-slider {
		&-hero {
			@include shape(0, null, null, 'house');
			bottom: -40%;
			right: -50rem;

			@include bp('medium') {
				bottom: -30%;
				right: -40rem;
			}

			@include bp('extra-large') {
				bottom: -5%;
				right: -20rem;
				@include scale(1.5)
			}
		}

		&-grid {
			@include shape(2, 'medium', 'extra-large');

			@include bp('extra-large') {
				top: -15%;
				left: -44rem;
			}
		}

		&-thumbs {
			top: 10%;
			right: -20rem
		}
	}

	//normal classes

	&__image {

		.jumbotron & {
			@include scale(.8);

			@include bp('extra-large') {
				@include scale(1.3)
			}
		}
	}
}
