.footer {
	background-color: var(--blue);
	position: relative;
	@include z-index('bungalow');

	&__row {

		&:first-child {
			padding-block: var(--sp-6x);
			border-bottom: .1rem solid var(--white)
		}

		&:last-child {
			padding-block: var(--sp-4x);

			@include bp('extra-large') {
				padding-block: var(--sp-2x)
			}
		}
	}

	&__container {
		@extend %grid;

		@include bp('extra-large') {
			display: flex;
			justify-content: space-between;

			&--reverse {
				flex-direction: row-reverse;
			}
		}
	}

	&__column {
		display: flex;
		align-items: center;

		&--wrap {
			flex-wrap: wrap;
		}
	}

	&__text {
		flex-basis: 100%;

		@include bp('medium') {
			flex-basis: auto;
			margin-inline-end: var(--sp-4x);
		}
	}

	&__social {
		&-list {
			display: flex;
			align-items: center;
		}

		&-item {
			margin-inline-end: var(--sp-3x);

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;
		max-width: 15rem;
		margin-inline-end: var(--sp-5x);
		margin-block-end: var(--sp-2x);

		@include bp('extra-large') {
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__figure {
		&--max-width {
			max-width: 20rem;
			margin-block-end: var(--sp-3x);

			@include bp('extra-large') {
				margin-bottom: 0;
				margin-inline-end: var(--sp-4x);
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
	}

	&__nav {
		margin-block-end: var(--sp-7x);
		flex-basis: 100%;

		@include bp('extra-large') {
			margin-bottom: 0;
			flex-basis: auto;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: var(--sp-3x);
		row-gap: var(--sp-4x); //sass-lint:disable-line no-misspelled-properties

		@include bp('medium') {
			display: flex;
		}
	}

	&__item {

		@include bp('medium') {

			//fix for safari < 15
			@supports not (aspect-ratio: 1 / 1) {
				margin-inline-end: var(--sp-3x);
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}