.error-page {
	padding-top: 10.3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	h1 {
		font-size: 15rem;
	}

	p {
		color: var(--red);
		max-width: 40rem;
		text-align: center;
		font-weight: var(--fw-regular);
	}

	.rte {
		&__link {
			margin-top: 8rem;

			.rte__link-copy {
				color: var(--red);
				font-weight: var(--fw-bold)
			}
		}
	}
}
