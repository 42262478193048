.rte {
	@extend %grid-smaller;

	&:last-child {
		padding-block-end: var(--sp-15x);
	}

	&__container {
		margin-block-end: var(--sp-10x);

		.rte:last-child & {
			&:last-child {
				margin-bottom: 0;
			}
		}

		+.slider-thumbs {
			padding-top: 0
		}

		p {
			text-align: justify;
			margin-block-end: var(--sp-4x);

			&:last-child {
				margin-bottom: 0;
			}

			+blockquote {
				margin-block-start: calc(var(--sp-4x) * -1);
			}
		}

		ul {
			list-style: inside square;
			margin-block-end: var(--sp-5x);

			&:last-child {
				margin-bottom: 0;
			}

			+blockquote {
				margin-block-start: calc(var(--sp-5x) * -1);
			}

		}

		blockquote {
			padding-block: var(--sp-6x);
		}
	}

	&__heading {
		margin-block-end: var(--sp-6x);
	}

	&__posheading,
	&__date,
	&__link {
		margin-block-end: var(--sp-2x);
	}

	&__date {
		display: block;
	}

	&__link {
		display: flex;
		align-items: center;

		.arrow__svg {
			@include scaleX(-1);
			margin-inline-end: var(--sp-1x);
		}

		&--reverse {
			display: inline-flex;
			flex-direction: row-reverse;

			.arrow__svg {
				transform: none;
			}
		}
	}

	&__source {
		margin-block-end: var(--sp-7x);
		display: block;
	}

	&__video {
		position: relative;
		aspect-ratio: 16 / 9; //sass-lint:disable-line no-misspelled-properties
		width: 100%;
		margin-block-end: var(--sp-5x);


		@supports not (aspect-ratio: 16 / 9) {
			height: 0;
			padding-bottom: 56.25%; // 16x9 
			overflow: hidden;
		}
	}

	&__iframe {
		border: 0;
		@include absolute(top 0 left 0);
		@include size(100%);
	}

}