.card-images {
	@extend %grid-small;
	padding-block-end: var(--sp-15x);

	&--gray {
		background-color: var(--gray)
	}

	&__list {
		@extend %list-overflow-scroll;

		@include bp('extra-large') {
			justify-content: center;
		}
	}

	&__item {
		margin-inline-end: var(--sp-4x);
		flex-shrink: 0;
		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}
	}

	&__grid {

		@include bp('medium') {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: var(--sp-6x);
			row-gap: var(--sp-6x); //sass-lint:disable-line no-misspelled-properties
		}

		&-gapless {

			@include bp('medium') {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}

			@include bp('extra-large') {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}

	&__box {
		margin-block-end: var(--sp-5x);

		&.is-hidden {
			opacity: 0;
			transform: translateY(-100%);
			pointer-events: none;
		}

		@include bp('medium') {
			margin-bottom: 0;
		}

		&-gapless {
			position: relative;
			aspect-ratio: 5 / 4; //sass-lint:disable-line no-misspelled-properties

			//fix for safari < 15
			@supports not (aspect-ratio: 5 / 4) {
				height: 30rem;
			}

			&.is-hidden {
				opacity: 0;
				transform: translateY(-100%);
				pointer-events: none;
			}
		}
	}

	&__figure {
		background-color: var(--red);

		&:hover {
			.card-images__image {
				@include rotate(8)
			}
		}

		&-gapless {
			background-color: var(--red);
			overflow: hidden;
			position: relative;
			@include size(100%);
			@extend %overlay;

			&:hover {
				&::after {
					display: none;
				}

				.card-images__image-gapless {
					@include rotate(-8);
					transform-origin: 0 0;
					transition: .3s transform ease(out-sine)
				}

				+.card-images__hovered {
					.card-images__caption-gapless {
						opacity: 1;
						transition: .3s opacity ease(out-sine)
					}
				}
			}
		}
	}

	&__hovered {
		pointer-events: none;
		@extend %position-center;

		&-image {
			margin-block-end: var(--sp-1x);
		}
	}

	&__figcaption {
		@include absolute(top 50% left 50%);
		transform: translate(-50%, -50%);
		@include z-index('sidewalk')
	}

	&__heading {
		margin-bottom: var(--sp-4x);
	}

	&__image {
		object-fit: cover;
		aspect-ratio: 1 / 1; //sass-lint:disable-line no-misspelled-properties

		&-gapless {
			object-fit: cover;
		}
	}

	&__caption {
		background-color: var(--white);
		padding-block-start: var(--sp-2x);

		&-gapless {
			opacity: 0;
		}
	}

	&__button {
		justify-content: center;
		width: 100%;
		padding-block: var(--sp-3x);

		.arrow__svg {
			@include rotate(90);
			margin-left: 0;
			margin-block-start: .5rem;
		}
	}
}