.map {
	@extend %grid-small;
	padding-block-end: var(--sp-14x);

	@include bp('extra-large') {
		display: flex;
		justify-content: space-between;
	}

	&__row {
		margin-block-end: var(--sp-5x);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__column {

		&:first-child {
			margin-block-end: var(--sp-6x);

			@include bp('extra-large') {
				margin-bottom: 0;
			}
		}

		@include bp('extra-large') {
			flex: 1;
		}
	}

	&__iframe {
		aspect-ratio: 4 / 3; //sass-lint:disable-line no-misspelled-properties
	}
}